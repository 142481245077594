/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scrollbar-color: #0d78ca black;
} */

body {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  font-size: 15px !important;
  line-height: 1.6 !important;
  color: #333333 !important;
  background-color: #fff !important;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
